<template>
    <transition name="slide" mode="out-in">
        <component :is="tag"
                   v-if="isActive"
                   class="tab__panel"
                   role="tabpanel">
            <slot></slot>
        </component>
    </transition>
</template>

<script>
import {ref, computed, inject} from "vue";

export default {
    props: {
        name: {
            type: String,
        },
        tag: {
            type: String,
            default: 'div'
        }
    },

    setup(props, {emit}) {
        const {
            active,
            panels
        } = inject('tabPanelsState', {
            active: ref(null),
            panels: ref([])
        });

        const isActive = computed(() => {
            return props.name === active.value;
        });

        return {
            isActive
        };
    }
}
</script>
