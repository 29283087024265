<template>
    <component :is="tag"
               role="tab"
               class="tabs__tab"
               :class="{'tabs__tab--active': isActive}"
               :aria-selected="isActive"
               @click.prevent="activateTab">
        <slot></slot>
    </component>
</template>

<script>
import {ref, computed, inject} from "vue";
import {slugToTitle} from "../../util/helpers";

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
        },
        tag: {
            type: String,
            default: 'li'
        },
    },

    setup(props, {emit}) {

        const {
            active,
            selectTab
        } = inject('tabsState', {
            active: ref(null),
            tabs: ref([]),
            selectTab: () => {
            }
        });

        const isActive = computed(() => {
            return props.name === active.value;
        });

        const tabLabel = computed(() => {
            return props.label ? props.label : slugToTitle(props.name);
        });

        const activateTab = () => {
            selectTab(props.name);
        };

        return {
            isActive,
            tabLabel,
            activateTab,
        };
    }
}
</script>
