import {ref} from 'vue';

const useUriFragments = () => {

    const currentHash = ref(null);

    const parseUrlHash = () => {
        let hash = window.location.hash;

        if (hash.length) {
            let matched = hash.match(/^#([a-z|\-]+)$/mi);
            currentHash.value = matched ? matched[1] : null;
        }

        return currentHash.value;
    };

    const setUrlHash = (hash) => {
        currentHash.value = hash.replace(/^#/, '');
        if (history.replaceState) {
            history.replaceState(null, null, `#${currentHash.value}`);
        }
    };

    const removeUrlHash = () => {
        currentHash.value = null;
        if (history.replaceState) {
            history.replaceState("", document.title, window.location.pathname + window.location.search);
        }
    };

    return {
        currentHash,
        parseUrlHash,
        setUrlHash,
        removeUrlHash,
    };
};

export default useUriFragments;
