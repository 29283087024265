import {h} from "vue";
import {slugToTitle} from "../../util/helpers";

export default {
    name: 'SlottableSubpage',

    props: {
        name: {
            type: String,
            required: true
        },

        title: {
            type: String,
        },

        render: {
            type: Function,
            default: h => null
        }
    },

    setup(props) {
        const header = h('header', {
            class: 'styleguide__subpage-header'
        }, [h('h2', {class: 'styleguide__subpage-title'}, props.title ? props.title : slugToTitle(props.name))])

        let content = h('div', {
            class: 'styleguide__subpage-content'
        }, props.render());

        return () => h('section', {
            id: props.name,
            class: props.name,
        }, [header, content]);
    },
};
