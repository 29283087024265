<template>
    <component :is="tag" class="tabs">
        <slot></slot>
    </component>
</template>

<script>
import {ref, computed, provide} from "vue";

export default {
    props: {
        // the 'active' tab
        modelValue: {
            type: [Object, String, Number]
        },
        tag: {
            type: String,
            default: 'ul'
        }
    },

    emits: ['update:modelValue'],

    setup(props, {attrs, slots, emit}) {
        const active = computed(() => {
            return props.modelValue?.value ? props.modelValue.value : props.modelValue;
        });

        const tabs = ref([]);

        function selectTab(tab) {
            emit('update:modelValue', tab);
        }

        provide('tabsState', {
            active,
            tabs,
            selectTab
        });

        return {
            active,
            tabs,
            selectTab,
        };
    }
}
</script>
