//
// Update cart icon count when cart updated
// ================================================

function updateCartCount() {
    jQuery.ajax({
        url: tm_ajax.ajax_url,
        type: 'post',
        dataType: "json",
        data: {
            action: 'update_cart_icon_count'
        },
        success: function (data) {
            document.querySelector('.cart-item-count').innerText = data;
        }
    });
}

document.addEventListener('DOMContentLoaded', function () {
    updateCartCount();
    jQuery(document.body).on('added_to_cart', () => updateCartCount());
    jQuery(document.body).on('updated_cart_totals', () => updateCartCount());
    jQuery(document.body).on('wc_cart_emptied', () => updateCartCount());
});
