// This is fired when the variation is locked in
document.addEventListener('DOMContentLoaded', function () {
    var $ = jQuery;

    $(".single_variation_wrap").on("show_variation", function (event, variation) {
        var location = new URL(window.location);
        var attributes = Object.entries(variation.attributes);
        var query = "";

        for (var i = 0; i < attributes.length; i++) {
            if (i === 0) {
                query += "?" + attributes[i][0] + "=" + attributes[i][1];
                continue;
            }

            query += "&" + attributes[i][0] + "=" + attributes[i][1];
        }

        if (query === location.search) {
            return;
        }

        window.history.replaceState("tmprod", document.title, location.origin + location.pathname + query);
    });
});
