const useFileExtensions = () => {
    const getExtension = (file) => {
        return file.split(/[#?]/)[0].split('.').pop().trim();
    };

    const swapFileExtension = (file, oldExtension, newExtension) => {
        return file.replace(
            RegExp(`\.${oldExtension.toLowerCase()}$`, 'i'),
            '.'+newExtension.toLowerCase().replace(/^\./, '')
        );
    };

    const matchFileTypeString = (ext, map) => {
        if (map.hasOwnProperty(ext)) {
            return map[ext];
        }
    };

    return {
        getExtension,
        swapFileExtension,
        matchFileTypeString,
    };
};

export default useFileExtensions;
