<template>
    <div>
        <p>Carousel with defaults:</p>
        <tm-carousel>
            <img src="https://picsum.photos/seed/place1/1600/600" alt="Alt one">
            <img src="https://picsum.photos/seed/place2/1600/600" alt="Alt two">
            <img src="https://picsum.photos/seed/place3/1600/600" alt="Alt three">
        </tm-carousel>

        <hr>

        <p>Carousel with navigation bullets:</p>
        <tm-carousel navigation="bullets">
            <img src="https://picsum.photos/seed/place1/1600/600" alt="Alt one">
            <img src="https://picsum.photos/seed/place2/1600/600" alt="Alt two">
            <img src="https://picsum.photos/seed/place3/1600/600" alt="Alt three">
        </tm-carousel>

        <hr>

        <p>Carousel using props (":per-view='2'") to override the default options:</p>

        <tm-carousel :per-view="2">
            <img src="https://picsum.photos/seed/place1/1600/600" alt="Alt one">
            <img src="https://picsum.photos/seed/place2/1600/600" alt="Alt two">
            <img src="https://picsum.photos/seed/place3/1600/600" alt="Alt three">
        </tm-carousel>

        <p>Carousel with navigation arrows on the outside:</p>
        <tm-carousel external-arrows>
            <img src="https://picsum.photos/seed/place1/1600/600" alt="Alt one">
            <img src="https://picsum.photos/seed/place2/1600/600" alt="Alt two">
            <img src="https://picsum.photos/seed/place3/1600/600" alt="Alt three">
        </tm-carousel>
        <hr>

        <p>Carousel with props for cards, breakpoints, gap, per-view:</p>
        <tm-carousel v-if="cards.length"
                     :cards="cards"
                     :per-view="3"
                     :gap="20"
                     :breakpoints="cardBreakpoints"></tm-carousel>
    </div>
</template>

<script>
import Carousel from "../../../../components/carousel/Carousel";
import Cards from "../../../../components/cards/Cards";
import demoCardData from "./../cards/demo-data";

export default {
    components: {
        'tm-carousel': Carousel,
        'tm-cards': Cards,
    },

    data() {
        return {
            demoCardCount: 3,
            cards: [],
            cardBreakpoints: {
                576: {perView: 1},
                992: {perView: 2},
                4000: {perView: 3}
            }
        };
    },

    mounted() {
        for (let i = 0; i < this.demoCardCount; i++) {
            this.cards.push({
                ...demoCardData,
                title: `${demoCardData.titleLine1} ${i + 1}`
            });
        }
    }
}
</script>
