export default {
    name: 'spacing',
    label: 'Spacing',
    items: [
        {
            name: 'padding',
            description: 'Used to control an element\'s padding.',
            helpers: [
                {
                    class: 'p-0',
                    properties: [
                        'padding: 0px;'
                    ]
                },
                {
                    class: 'p-4',
                    properties: [
                        'padding: 1rem;'
                    ]
                },
            ]
        },
        {
            name: 'margin',
            description: 'Used to control an element\'s margin.',
            helpers: [
                {
                    class: 'm-0',
                    properties: [
                        'margin: 0px;'
                    ]
                },
                {
                    class: 'm-4',
                    properties: [
                        'margin: 1rem;'
                    ]
                },
            ]
        },
    ]
};
