export default {
    name: 'SubpageTab',

    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
        },
    },

    setup(props, {attrs, slots, emit}) {
        return () => {
        };
    }
};
