import Colors from "./colors";
import Typography from "./typography";
import Spacing from "./spacing";
import Icons from "./icons";
import Messages from "./messages";

export default {
    design: [
        {
            name: 'colors',
            label: 'Colors',
            component: Colors
        },
        {
            name: 'typography',
            label: 'Typography',
            component: Typography
        },
        {
            name: 'spacing',
            label: 'Spacing',
            component: Spacing
        },
        {
            name: 'icons',
            label: 'Icons',
            component: Icons
        },
        {
            name: 'messages',
            label: 'Messages',
            component: Messages
        },
    ],
};
