<template>
    <tm-map :map-data="map_data"></tm-map>
</template>

<script>
export default {
    props: {},

    data() {
        return {
            map_data: {
                display_info_windows: true,
                locations: [
                    {
                        lat: '41.4822',
                        lng: '-82.6835',
                        info_window: {
                            html: null,
                            title: 'Cedar Point',
                            title_link: null,
                            city: 'Sandusky',
                            state: 'OH',
                            zip: '44870',
                            street_address: '1 Cedar Point Dr',
                            phone: '(419) 627-2350',
                            email: 'hello@cedarpoint.com',
                            more_text: 'More info',
                            more_link: 'https://www.cedarpoint.com',
                        }
                    },
                    {
                        lat: '41.2808',
                        lng: '-81.5678',
                        info_window: {
                            html: '<ul><li>Cuyahoga Valley National Park</li><li>is really</li><li>really fun.</li></ul>',
                            city: null,
                            state: null,
                            phone: null,
                            email: null,
                        }
                    },
                    {
                        lat: '41.3367',
                        lng: '-81.8253',
                        info_window: {
                            title: 'Ranger Lake',
                            city: 'Strongsville',
                            state: 'OH',
                            more_link: 'https://en.wikipedia.org/wiki/Ranger_Lake',
                        }
                    },
                ]
            }
        };
    }
}
</script>
