class MapService {
    constructor(options) {
        this.options = options;
        this.api = null;
    }

    static init(options) {
        let service = new MapService(options);
        service.loadProvider();
        return service;
    }

    get apiKey() {
        return this.options.apiKey;
    }

    loadProvider() {
        this.loadMapScript();
    }

    loadMapScript() {
        return new Promise(resolve => {
            let script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&v=weekly`;
            script.async = true;
            document.body.appendChild(script);
            resolve();
        });
    }
}

export default MapService;
