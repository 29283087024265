<template>
    <component :is="tag" class="tab__panels">
        <slot></slot>
    </component>
</template>

<script>
import {ref, computed, provide} from "vue";

export default {
    props: {
        // the 'active' tab
        modelValue: {
            type: [Object, String, Number]
        },
        tag: {
            type: String,
            default: 'div'
        }
    },

    setup(props, {emit}) {
        const active = computed(() => {
            return props.modelValue?.value ? props.modelValue.value : props.modelValue;
        });

        const panels = ref([]);

        provide('tabPanelsState', {
            active,
            panels
        });

        return {};
    }
}
</script>
