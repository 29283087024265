import {createApp} from 'vue';

import components from '@/components/global';

import GMapper from "@/plugins/g-mapper";

// Recursive function that loops through all the child elements of the
// instance container and gathers the custom element tag names into an array
function getVueInstanceComponentTags(instance, node, vueInstanceComponentTags) {

    for (let i = 0; i < node.childNodes.length; i++) {
        let child = node.childNodes[i];
        getVueInstanceComponentTags(instance, child, vueInstanceComponentTags);

        if (child.tagName) {
            let tagName = child.tagName.toLowerCase();

            if (tagName.startsWith('tm-')) {
                vueInstanceComponentTags[instance].push(tagName);
            }
        }
    }
}

function mainInit() {
    // Get all containing elements that have vue components inside
    const instanceContainers = document.querySelectorAll(".vue-instance");
    console.log(instanceContainers);

    // Initialize a variable for holding a list of component tags for each Vue instance
    let vueInstanceComponentTags = [];

    let usingMaps = window.hasOwnProperty('tm_maps') && window.tm_maps.hasOwnProperty('api_key') && window.tm_maps.api_key !== undefined;

    // Initialize a variable for containing all the vue instances
    let vueInstances = [];

    // Loop through each of
    for (let i = 0; i < instanceContainers.length; i++) {

        // Add a key to the vueInstanceComponentTags variable to hold this instances list of tags
        vueInstanceComponentTags[i] = [];

        // Create the new Vue instance
        vueInstances[i] = createApp({});

        // get an array of vue instance component tags
        getVueInstanceComponentTags(i, instanceContainers[i], vueInstanceComponentTags);

        // Loop through the custom element tags found inside the instance container
        for (let j = 0; j < vueInstanceComponentTags[i].length; j++) {

            // Register Vue components for each of the custom element tags
            for (const [name, component] of Object.entries(components)) {
                if (name === vueInstanceComponentTags[i][j]) {
                    vueInstances[i].component(name, component);
                }
            }
        }

        // Conditionally register plugins
        if (usingMaps) {
            vueInstances[i].use(GMapper, {
                componentPrefix: 'tm-',
                apiKey: window.tm_maps?.api_key
            });
        }

        // Mount it!
        vueInstances[i].mount(instanceContainers[i]);
    }
}

mainInit();

if(jQuery('body').hasClass("woocommerce-shop")) {
    jQuery(window).on("wcpf_after_ajax_filtering", function(){
        mainInit();
    });
}
