<template>
    <section class="styleguide__section styleguide__section--overview">
        <header><h2>Overview</h2></header>

        <p>The TM styleguide / design system is a work in progress.</p>
    </section>
</template>

<script>
export default {}
</script>
