<script>
import {ref, h} from "vue";
import SubpageTab from "./subpage-tab";
import {slugToTitle} from "../../util/helpers";
import useTabs from "../../composables/use-tabs";

export default {
    name: 'SubpageTabs',

    components: {
        'sub-page-tab': SubpageTab,
    },

    props: {
        tabs: {
            type: Object,
            required: true
        }
    },

    setup(props, {emit, slots, attr}) {

        const {
            setActiveTab,
            createTabsComponent,
            createTabComponent,
            createTabPanelsComponent,
            createTabPanelComponent,
            tabs,
            tabPanels,
            addTab,
            addTabPanel,
        } = useTabs();

        const children = ref([]);

        let tabIndex = 0;
        for (let [tabName, tabComponent] of Object.entries(props.tabs)) {
            if (tabIndex === 0) {
                setActiveTab(tabName);
            }

            let newTab = createTabComponent(
                slugToTitle(tabName),
                {
                    name: tabName,
                    class: 'styleguide__tab'
                });
            addTab(newTab, tabIndex);

            let newTabPanel = createTabPanelComponent({
                name: tabName,
                class: 'styleguide__tab-panel'
            }, () => h(tabComponent, {class: 'styleguide__tab-content'}));
            addTabPanel(newTabPanel, tabIndex);

            tabIndex++;
        }

        if (tabs.value.length) {
            children.value.push(createTabsComponent(
                {class: 'styleguide__tabs'},
                tabs.value
            ));
            children.value.push(createTabPanelsComponent(
                {class: 'styleguide__tab-panels'},
                tabPanels.value
            ));
        } else {
            children.value.push(...slots.default());
        }

        return () => children.value;
    }
}
</script>
