<template>
    <div>
        <p>Youtube video:</p>
        <div class="vue-instance">
            <tm-video
                type="youtube"
                src="https://www.youtube.com/watch?v=_tUts7GGL94"
                :controls="true"
            >
            </tm-video>
        </div>

        <hr>

        <p>Youtube video with custom thumbnail:</p>
        <div class="vue-instance">
            <tm-video
                type="youtube"
                src="https://www.youtube.com/watch?v=RzwjE_BVHX4"
                max-width="720px"
                custom-thumbnail="https://entropyresins.com/app/uploads/featured-video-preview.png"
                :controls="true"
                :show-title="false"
            >
            </tm-video>
        </div>

        <hr>

        <!--
        <p>Youtube video with no preview:</p>
        <div class="vue-instance">
            <tm-video
                type="youtube"
                src="https://www.youtube.com/watch?v=_tUts7GGL94"
                max-width="720px"
                :autoplay="true"
                :show-preview="false"
            >
            </tm-video>
        </div>

        <hr>
        -->

        <p>Teaser video as custom thumbnail:</p>
        <div class="vue-instance">
            <tm-video
                type="youtube"
                src="https://www.youtube.com/watch?v=M-L1KoyVkXs"
                max-width="720px"
                teaser-thumbnail="https://www.youtube.com/watch?v=wasNUrRofZw"
                teaser-type="youtube"
                :modal="true"
            >
                <template v-slot:button>
                    <h3 class="entropy-watch-now">Watch Now</h3>
                </template>
            </tm-video>
        </div>

        <hr>

        <p>Server hosted .mp4:</p>
        <div class="vue-instance">
            <tm-video
                type="hosted"
                src="https://base.tmprod.com/app/uploads/big_buck_bunny_720p_surround.mp4"
                max-width="720px"
                custom-thumbnail="https://picsum.photos/seed/place1/1600/600"
                custom-title="With a custom title. This is the custom title."
                :controls="true"
            >
            </tm-video>
        </div>

        <hr>

        <p>Server hosted .mp4 with youtube teaser video as custom thumbnail:</p>
        <div class="vue-instance">
            <tm-video
                type="hosted"
                src="https://base.tmprod.com/app/uploads/big_buck_bunny_720p_surround.mp4"
                max-width="720px"
                teaser-thumbnail="https://www.youtube.com/watch?v=wasNUrRofZw"
                teaser-type="youtube"
                :modal="true"
            >
            </tm-video>
        </div>

        <hr>

        <p>Opposite of ^:</p>
        <div class="vue-instance">
            <tm-video
                type="youtube"
                src="https://www.youtube.com/watch?v=wasNUrRofZw"
                max-width="720px"
                teaser-thumbnail="https://base.tmprod.com/app/uploads/big_buck_bunny_720p_surround.mp4"
                teaser-type="hosted"
                :modal="true"
            >
            </tm-video>
        </div>

        <hr>

        <p><strong>Lazy video (server hosted) (uses IntersectionObserver)</strong></p>
        <p><small>A great use case is a background/banner video, especially one below the fold.</small></p>
        <div class="vue-instance">
            <tm-lazy-video
                video-url="https://base.tmprod.com/app/uploads/2023/06/test-video-hd-1280x720-30fps.mp4"
                video-poster="https://base.tmprod.com/app/uploads/2023/06/test-video-poster-image.jpg"></tm-lazy-video>
        </div>

        <hr>

        <p>Vimeo video:</p>
        <div class="vue-instance">
            <tm-video
                type="vimeo"
                src="https://player.vimeo.com/video/64654583"
                max-width="720px"
            >
            </tm-video>
        </div>
    </div>
</template>

<script>
import VideoPlayer from "@/components/video/VideoPlayer";
import LazyVideo from "@/components/video/LazyVideo";

export default {
    components: {
        'tm-video': VideoPlayer,
        'tm-lazy-video': LazyVideo,
    },

    data() {
        return {};
    },
}
</script>
