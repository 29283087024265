(function () {

    const spacingTemplate = document.getElementsByClassName('spacing-example');
    const body = document.getElementsByTagName('body')[0];
    const bodyFontSizeEl = document.getElementById('body-font-size');
    const bodyLineHeightEl = document.getElementById('body-line-height');
    const pixelValues = document.getElementsByClassName('pixel-value');

    if (spacingTemplate && spacingTemplate.length) {
        function calculateSpacingStats() {
            let bodyFontSize = window.getComputedStyle(body, null).getPropertyValue('font-size');
            let bodyLineHeight = window.getComputedStyle(body, null).getPropertyValue('line-height');

            bodyFontSizeEl.innerHTML = parseFloat(bodyFontSize).toFixed(2) + 'px';
            bodyLineHeightEl.innerHTML = (parseFloat(bodyLineHeight) / parseFloat(bodyFontSize)).toFixed(1);

            for (let i = 0; pixelValues.length > i; i++) {
                let unitModifier = pixelValues[i].getAttribute('data-modifier');
                let pixelValue = parseFloat(unitModifier) * parseFloat(bodyLineHeight);
                pixelValues[i].innerHTML = pixelValue.toFixed(2) + 'px';
            }
        }

        window.addEventListener("resize", calculateSpacingStats);
        calculateSpacingStats();
    }
})();
