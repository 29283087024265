import {defineComponent, ref, onMounted, provide, inject} from "vue";
import useIdentifier from "../../composables/use-identifier";

const MapMarker = defineComponent({
    name: 'MapMarker',

    props: {
        id: {
            type: [String, Number],
        },
        lat: {
            type: Number,
            required: true
        },
        lng: {
            type: Number,
            required: true
        },
        info: {
            type: String,
        }
        // TODO: define additional marker params
    },

    setup(props, {slots, emit}) {

        const markerId = useIdentifier(props);

        const marker = ref(null);

        provide('marker', marker);

        const {
            createMapMarker,
            addMarkerToMap,
        } = inject('mapState');

        onMounted(() => {
            marker.value = createMapMarker(
                props.lat,
                props.lng
            );

            addMarkerToMap(marker.value);
        });

        // no need to render anything since Google Maps handles rendering
        return () => {
        };
    }
});

export default MapMarker;
