<template>
    <div>
        <ul class="color-list primary">
            <li><i></i>Primary Color</li>
            <li><i></i>Primary Color - Light</li>
            <li><i></i>Primary Color - Dark</li>
        </ul>
        <ul class="color-list secondary">
            <li><i></i>Secondary Color</li>
            <li><i></i>Secondary Color - Light</li>
            <li><i></i>Secondary Color - Dark</li>
        </ul>
        <ul class="color-list tertiary">
            <li><i></i>Tertiary Color</li>
            <li><i></i>Tertiary Color - Light</li>
            <li><i></i>Tertiary Color - Dark</li>
        </ul>
        <ul class="color-list shades">
            <li><i></i>White</li>
            <li><i></i>Light Color</li>
            <li><i></i>Medium Color</li>
            <li><i></i>Dark Color</li>
            <li><i></i>Black</li>
        </ul>
        <ul class="color-list action">
            <li><i></i>Action Color</li>
            <li><i></i>Base Background Color</li>
            <li><i></i>Secondary Background Color</li>
        </ul>
        <ul class="color-list alert">
            <li><i></i>Alert Color</li>
            <li><i></i>Error Color</li>
            <li><i></i>Notice Color</li>
            <li><i></i>Success Color</li>
        </ul>
    </div>
</template>

<script>
export default {}
</script>
