<template>
    <section id="typefaces" class="type">
        <header class="section-header container">
            <h3 class="section-title">
                Typefaces
            </h3>
            <hr>
        </header>
        <div class="section-body container">
            <ul>
                <li>Primary Font - <span>Area</span></li>
                <li>Secondary Font - <span>Franklin Gothic URW</span></li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {}
</script>
