import mitt from "mitt";

class Dispatcher {
    constructor() {
        this.events = mitt();
    }

    static init() {
        return new Dispatcher();
    }

    all() {
        return this.events.all;
    }

    emit(eventType, eventData) {
        return this.events.emit(eventType, eventData);
    }

    on(eventType, handler) {
        return this.events.on(eventType, handler);
    }

    off(eventType, handler) {
        return this.events.off(eventType, handler);
    }

    count() {
        return this.all().size;
    }

    hasEvents() {
        return this.count() > 0;
    }

    removeAll() {
        // todo
    }
}

export default Dispatcher;
