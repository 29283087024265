import Callouts from "./Callouts";
import Columns from "./Columns";

export default {
    shortcodes: [
        {
            name: 'callouts',
            label: 'Callouts',
            component: Callouts
        },
        {
            name: 'columns',
            label: 'Columns',
            component: Columns
        },
    ],
};
