<template>
    <div>
        <helper-links :items="helpers"></helper-links>
    </div>
</template>

<script>
import HelpersLinkView from "../../helpers/HelpersLinkView";

export default {
    components: {
        'helper-links': HelpersLinkView,
    },

    data() {
        return {
            helpers: [
                {
                    name: 'spacing.margin',
                    label: 'Margin'
                },
                {
                    name: 'spacing.padding',
                    label: 'Padding'
                },
            ]
        };
    }
}
</script>
