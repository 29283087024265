import {ref, computed, provide, h} from "vue";
import {slugToTitle} from "../../util/helpers";
import Tabs from "../../components/tabs/Tabs";
import Tab from "../../components/tabs/Tab";
import TabPanels from "../../components/tabs/TabPanels";
import TabPanel from "../../components/tabs/TabPanel";
import useTabs from "../../composables/use-tabs";

export default {
    components: {
        Tab,
        Tabs,
        TabPanels,
        TabPanel,
    },

    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
        },
        startTab: {
            type: String,
        },
    },

    setup(props, {attrs, slots, emit}) {

        const subpageTitle = computed(() => {
            return props.title ? props.title : slugToTitle(props.name);
        });

        const subpageClasses = computed(() => {
            return [
                'styleguide__subpage',
                `styleguide__subpage--${props.name}`,
                props.name
            ];
        });

        const header = h('header', {
            class: 'styleguide__subpage-header'
        }, [h('h2', {class: 'styleguide__subpage-title'}, subpageTitle.value)])

        const defaultSlot = slots.default();

        const children = ref([
            header
        ]);

        const {
            activeTab,
            setActiveTab,
            createTabsComponent,
            createTabComponent,
            createTabPanelsComponent,
            createTabPanelComponent,
            tabs,
            tabPanels,
            addTab,
            addTabPanel,
        } = useTabs(props.startTab ? props.startTab : defaultSlot[0]?.props?.name)

        provide('activeTab', activeTab);
        provide('setActiveTab', setActiveTab);

        const parseSubpageTabs = (items) => {
            items.forEach((child, index) => {
                if (child.type.name === 'SubpageTab') {
                    let newTab = createTabComponent(
                        child.props?.label ? child.props?.label : slugToTitle(child.props?.name),
                        {
                            ...child.props,
                            class: 'styleguide__tab'
                        });
                    addTab(newTab, index);

                    let newTabPanel = createTabPanelComponent({
                        ...child.props,
                        class: 'styleguide__tab-panel'
                    }, child.children);
                    addTabPanel(newTabPanel, index);
                }
            });
        };

        if (defaultSlot) {
            parseSubpageTabs(defaultSlot);
        }

        if (tabs.value.length) {
            children.value.push(createTabsComponent(
                {class: 'styleguide__tabs'},
                tabs.value
            ));
            children.value.push(createTabPanelsComponent(
                {class: 'styleguide__tab-panels'},
                tabPanels.value
            ));
        } else {
            children.value.push(...defaultSlot);
        }

        return () => h(
            'section',
            {
                id: props.name,
                class: subpageClasses.value
            },
            children.value
        );
    }
};
