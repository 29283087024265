//
// my-account page
// __________________________________________
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementsByTagName('body')[0].classList.contains('my-account')) {
        const addressContainer = document.getElementsByClassName('woocommerce-Addresses');
        addressContainer[0].classList.add('cards');

        const addresses = document.getElementsByClassName('woocommerce-Address');
        for (const address of addresses) {
            address.classList.add('card');

            const cardHeader = address.children[0];
            cardHeader.classList.add('card-header');

            const cardTitle = cardHeader.children[0];
            cardTitle.classList.add('card-title');

            const cardBody = address.children[1];
            cardBody.classList.add('card-body');
        }
    }
});
