<template>
    <div>
        <div class="type base">
            <a class="button">Default (Primary)</a>
            <a class="button secondary">Secondary</a>
            <a class="button tertiary">Tertiary</a>
        </div>
        <div class="type hollow">
            <a class="button hollow">Hollow (Primary)</a>
            <a class="button hollow secondary">Hollow (Secondary)</a>
            <a class="button hollow tertiary">Hollow (Tertiary)</a>
        </div>
        <div class="type naked">
            <a class="button naked">Naked (Primary)</a>
            <a class="button naked secondary">Naked (Secondary)</a>
            <a class="button naked tertiary">Naked (Tertiary)</a>
        </div>
        <div class="type wp-pagenavi" role="navigation">
            <a class="first" aria-label="First Page" href="#">« First</a>
            <a class="previouspostslink" rel="prev" aria-label="Previous Page" href="#">«</a>
            <span class="extend">...</span>
            <a class="page smaller" title="Page 2" href="#">2</a><span aria-current="page" class="current">4</span>
            <a class="page larger" title="Page 5" href="#">5</a>
            <span class="extend">...</span>
            <a class="page" title="Page 10" href="#">10</a>
            <a class="larger page" title="Page 20" href="#">20</a>
            <span class="extend">...</span>
            <a class="nextpostslink" rel="next" aria-label="Next Page" href="#">»</a>
            <a class="last" aria-label="Last Page" href="#">Last »</a>
        </div>
    </div>
</template>

<script>
export default {}
</script>
