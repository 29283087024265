import HelpersList from "./HelpersList";
import backgrounds from "./backgrounds";
import spacing from "./spacing";
import typography from "./typography";

export const helperCategories = [
    backgrounds,
    spacing,
    typography,
];

export const helperTabs = helperCategories.map((category) => {
    return {
        name: `helpers--${category.name}`,
        label: category.label || category.name,
        component: HelpersList,
        args: {
            ...category,
            title: category.label
        }
    };
});

export default {
    helpers: helperTabs
};
