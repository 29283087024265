<template>
    <div>
        <h1>
            <span><small>Heading 1 small text</small></span>
            Heading 1 text <mark>with mark</mark>
        </h1>
        <h2>
            <span><small>Heading 2 small text</small></span>
            Heading 2 text <mark>with mark</mark>
        </h2>
        <h3>
            <span><small>Heading 3 small text</small></span>
            Heading 3 text <mark>with mark</mark>
        </h3>
        <h4>
            <span><small>Heading 4 small text</small></span>
            Heading 4 text <mark>with mark</mark>
        </h4>
        <h5>
            Heading 5 text <mark>with mark</mark>
        </h5>
        <h6>
            Heading 6 text <mark>with mark</mark>
        </h6>
    </div>
</template>

<script>
export default {}
</script>
