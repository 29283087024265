<template>
    <div class="styleguide">
        <slot name="sidebar">
            <sidebar-panel
                class="styleguide__sidebar"
                :items="sidebarItems"
                @item:clicked="onItemClicked"></sidebar-panel>
        </slot>

        <section v-if="currentComponent" class="styleguide__content">
            <component :is="currentComponent"
                       :render="getSectionSlotContent(currentSection)"
                       :class="`styleguide__section styleguide__section--${currentSection.name}`"
                       v-bind="{ ...(currentSection.args ? currentSection.args : undefined) }"></component>
        </section>
    </div>
</template>

<script>
import {ref, computed, watch, provide, onMounted} from "vue";
import useUriFragments from "./../../composables/use-uri-fragments";
import {slugToTitle} from "../../util/helpers";
import sections from "./sections";
import SidebarPanel from "../../components/sidebar/SidebarPanel";
import StyleguideOverview from "./StyleguideOverview";
import {helperCategories} from "./helpers";

export default {
    components: {
        SidebarPanel,
    },

    setup(props, {slots, emit}) {
        const {
            currentHash,
            parseUrlHash,
            setUrlHash,
            removeUrlHash,
        } = useUriFragments();

        const activeSection = ref(null);

        provide('activeSection', activeSection);

        const helpersMap = computed(() => {
            let helpers = new Map();
            for (let helperCategory of helperCategories) {
                for (let helper of helperCategory.items) {
                    let helperKey = `${helperCategory.name}.${helper.name}`;
                    helpers.set(helperKey, {
                        ...helper,
                        tab: `helpers--${helperCategory.name}`,
                        key: helperKey
                    });
                }
            }
            return helpers;
        });

        provide('helpers', helpersMap);

        const usedSlots = ref(slots);

        const slottedContent = computed(() => {
            let usedSlots = {};
            for (let [slotName, slot] of Object.entries(slots)) {
                let names = slotName.split(':');

                let slotData = {
                    slotName,
                    componentName: names[names.length - 1],
                    levels: names,
                    content: slot
                };

                if (usedSlots.hasOwnProperty(names[0])) {
                    usedSlots[names[0]].push(slotData);
                } else {
                    usedSlots[names[0]] = [slotData];
                }
            }
            return usedSlots;
        });

        const getSlotContentByName = (name, defaultVal = undefined) => {
            if (usedSlots.value.hasOwnProperty(name)) {
                return usedSlots.value[name];
            }
            return defaultVal;
        };

        provide('getSlotContentByName', getSlotContentByName);

        const getSectionSlotContent = (section) => {
            if (section.slotContent !== undefined && section.slotContent.hasOwnProperty('content')) {
                return section.slotContent.content;
            }
        };

        const sidebarItems = computed(() => {
            let items = [{
                name: 'overview',
                label: 'Overview',
                component: StyleguideOverview,
                active: true
            }];
            for (const [sectionCategory, sectionItems] of Object.entries(sections)) {
                items.push({
                    name: sectionCategory,
                    label: slugToTitle(sectionCategory),
                    children: sectionItems
                });
            }
            return items;
        });

        const sectionMap = computed(() => {
            let items = new Map();
            for (const sectionCategory of sidebarItems.value) {
                if (!sectionCategory.hasOwnProperty('children')) {
                    items.set(sectionCategory.name, sectionCategory);
                    continue;
                }

                for (const item of sectionCategory.children) {
                    let slotContent;

                    if (slottedContent.value.hasOwnProperty(sectionCategory.name)) {
                        slotContent = slottedContent.value[sectionCategory.name].find(slotItem => {
                            return slotItem.componentName === item.name;
                        });
                    }

                    items.set(item.name, {
                        ...item,
                        category: sectionCategory.name,
                        slotContent: slotContent
                    });
                }
            }
            return items;
        });

        const currentSection = computed(() => {
            if (!activeSection.value || !sectionMap.value.has(activeSection.value)) {
                // if no active section, return overview section
                return sectionMap.value.get('overview');
            }

            return sectionMap.value.get(activeSection.value);
        });

        const currentComponent = computed(() => {
            if (currentSection.value && currentSection.value.hasOwnProperty('component')) {
                return currentSection.value.component;
            }
        });

        const setActiveSection = (name) => {
            activeSection.value = name;
            setUrlHash(name);
        };

        provide('setActiveSection', setActiveSection);

        const onItemClicked = (item) => {
            setActiveSection(item);
        };

        const checkHash = () => {
            parseUrlHash();

            if (currentHash.value && activeSection.value !== currentHash.value) {
                setActiveSection(currentHash.value);
            }
        };

        watch(currentHash, () => {
            if (currentHash.value === 'overview') {
                removeUrlHash();
            }
        });

        onMounted(() => {
            checkHash();
        });

        return {
            currentHash,
            activeSection,
            sidebarItems,
            onItemClicked,
            sectionMap,
            currentSection,
            currentComponent,
            getSectionSlotContent,
        };
    }
}
</script>
