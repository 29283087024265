import Overview from "./Overview";
import Helpers from "./Helpers";

/**
 * Tabs: Spacing
 */
export default {
    overview: Overview,
    helpers: Helpers,
};
