<template>
    <component :is="wrapperTag" ref="wrapper" class="lazy-video">
        <slot name="video">
            <video autoplay loop muted playsinline
                   :poster="videoPoster">
                <template v-if="loaded">
                    <source :src="video.src"
                            :data-src="video.dataSrc"
                            :type="video.type">
                </template>
            </video>
        </slot>

        <slot></slot>
    </component>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import useIntersectionObserver from "../../composables/use-intersection-observer";
import useFileExtensions from "../../composables/use-file-extensions";

const videoTypes = {
    webm: 'video/webm',
    mp4: 'video/mp4',
    mov: 'video/mov',
};

export default {
    props: {
        wrapperTag: {
            type: String,
            default: 'div'
        },
        videoUrl: {
            type: String
        },
        videoType: {
            type: String
        },
        videoPoster: {
            type: String,
        },
    },

    setup(props, { emit }) {

        const wrapper = ref(null);

        const video = ref({
            src: null,
            dataSrc: null,
            type: null,
        });

        const loaded = ref(false);

        const {
            getExtension,
            matchFileTypeString
        } = useFileExtensions();

        const initVideoData = () => {
            video.value.dataSrc = props.videoUrl;
            let extension = getExtension(video.value.dataSrc);
            video.value.type = matchFileTypeString(extension, videoTypes);
        };

        const loadVideo = (el) => {
            video.value.src = props.videoUrl;
            loaded.value = true;
        };

        const observerCallback = (entries) => {
            if (loaded.value) {
                return;
            }

            // iterate over each entry
            entries.forEach(entry => {
                // process just the videos that are intersecting.
                // isIntersecting is a property exposed by the interface
                if (entry.isIntersecting) {
                    loadVideo(entry.target);

                    // the video is now in place, stop watching
                    unobserve(entry.target);
                }
            });
        };

        const {
            initObserver,
            observe,
            unobserve,
            disconnect,
        } = useIntersectionObserver()

        onMounted(async () => {
            initVideoData();
            await initObserver(observerCallback);
            observe(wrapper.value)
        });

        onBeforeUnmount(() => {
            disconnect();
        });

        return {
            wrapper,
            loaded,
            video,
        };
    }
}
</script>
