import {ref, unref} from 'vue';

const mapsApi = ref(null);

const useMapsApi = (dispatcher) => {

    const events = dispatcher ? unref(dispatcher) : null;

    const _tries = ref(0);

    const resolveMapsApi = () => {
        return new Promise((resolve, reject) => {
            if (window.google?.maps) {
                resolve(window.google.maps);
            } else if (_tries.value < 10) {
                _tries.value++;
                setTimeout(async () => {
                    return resolve(await resolveMapsApi());
                }, 250);
            }
        });
    };

    const initMapsApi = async () => {
        if (mapsApi.value !== null) {
            return mapsApi;
        }

        mapsApi.value = await resolveMapsApi();

        if (events) {
            events.emit('api:ready', mapsApi.value);
        }

        return mapsApi;
    };

    return {
        mapsApi,
        initMapsApi,
    };
};

export default useMapsApi;
