<template>
    <div>
        <!-- Forms -->
        <div id="forms"></div>

        <!--Gravity Form-->
        <section class="gravity-form mb-md">
            <header class="section-header container">
                <h3 class="section-title">
                    Gravity Form
                </h3>
                <hr>
            </header>
            <div class="container">
                <component :is="slotContent"></component>
            </div>
        </section>

        <!--Native Form-->
        <section class="native-form mb-md">
            <header class="section-header container">
                <h3 class="section-title">
                    Native Form Elements
                </h3>
                <hr>
            </header>
            <div class="section-body container">
                <form>
                    <fieldset>
                        <label for="text-field">Text Field</label>
                        <input type="text" id="text-field" placeholder="type here..."/>
                    </fieldset>
                    <fieldset>
                        <label for="number-field">Number Field</label>
                        <input type="number" id="number-field" placeholder="numbers only please..."/>
                    </fieldset>
                    <fieldset>
                        <input type="checkbox" id="check1">
                        <label for="check1">Check 1</label>
                        <input type="checkbox" id="check2">
                        <label for="check2"> Check 2</label>
                        <input type="checkbox" id="check3">
                        <label for="check3">Check 3</label>
                    </fieldset>
                    <fieldset>
                        <input type="radio" name="radio1" id="radio1"><label for="radio1">Radio 1</label>
                        <input type="radio" name="radio1" id="radio2"><label for="radio2">Radio 2</label>
                        <input type="radio" name="radio1" id="radio3"><label for="radio3">Radio 3</label>
                    </fieldset>
                    <fieldset>
                        <label for="text-block">Text Area</label>
                        <textarea name="" id="text-block" cols="30" rows="10"></textarea>
                    </fieldset>
                    <fieldset>
                        <label for="file-upload">File Upload</label>
                        <input type="file" id="file-upload" name="file-upload">
                    </fieldset>
                    <fieldset>
                        <label for="dropdown">Select Dropdown</label>
                        <select name="" id="dropdown">
                            <option value="">--Please choose an option--</option>
                            <option value="dog">Dog</option>
                            <option value="cat">Cat</option>
                            <option value="hamster">Hamster</option>
                            <option value="parrot">Parrot</option>
                        </select>
                    </fieldset>

                    <fieldset>
                        <button class="secondary">Reset</button>
                        <input type="submit">
                    </fieldset>

                </form>
            </div>
        </section>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    setup() {
        const getSlotContentByName = inject('getSlotContentByName');

        const slotContent = getSlotContentByName('elements:forms');

        return {
            slotContent,
        };
    }
}
</script>
