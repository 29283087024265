export default {
    name: 'typography',
    label: 'Typography',
    items: [
        {
            name: 'text_color',
            label: 'Text Color',
            description: 'Used to control the text color of an element.',
            helpers: [
                {
                    class: 'text-primary',
                    properties: [
                        'color: $primary-color;'
                    ]
                },
                {
                    class: 'text-white',
                    properties: [
                        'color: #fff;'
                    ]
                },
            ]
        }
    ]
};
