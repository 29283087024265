import MapService from "./map-service";
import Map from "./Map";
import MapMarker from "./map-marker";
import MapDemo from "./demo/MapDemo";

const GMapper = {
    install: (app, options) => {
        let mapOptions = {
            // default options
            componentPrefix: 'g-',
            apiKey: null,
            ...options
        };

        if (!window.hasOwnProperty('mapService')) {
            window.mapService = MapService.init(mapOptions);
        }

        app.config.globalProperties.$maps = window.mapService;

        app.component(`${mapOptions.componentPrefix}map`, Map);
        app.component(`${mapOptions.componentPrefix}map-demo`, MapDemo);
        app.component(`${mapOptions.componentPrefix}map-marker`, MapMarker);
    }
};

export default GMapper;
