import {ref, computed, watch, provide, unref, readonly, onMounted, onBeforeUnmount} from 'vue';
import useMapsApi from "./use-maps-api";
import useIdentifier, {generateId} from "../../composables/use-identifier";
import Dispatcher from "./Dispatcher";

// map default options
export const mapOptions = {
    zoom: 8,
    shouldFocus: true,
    singleInfoWindow: true,
};

const mapEvents = ref(null);

const useMap = (props) => {

    if (mapEvents.value === null) {
        mapEvents.value = Dispatcher.init();
    }

    const {
        mapsApi,
        initMapsApi,
    } = useMapsApi();

    const createOnMount = ref(props.createOnMount || true);

    const mapId = useIdentifier(props);

    const mapElement = ref(null);

    const mapLoaded = ref(false);

    const map = ref(null);

    const mapMarkers = ref(new Map());

    const mapMarkerCount = computed(() => mapMarkers.value.size);

    const hasMapMarkers = computed(() => mapMarkerCount.value > 0);

    const mapInfoWindows = ref(new Map());

    const mapBounds = ref(null);

    provide('mapsApi', mapsApi);
    provide('mapEvents', mapEvents);
    provide('map', map);

    const createMap = () => {
        return new Promise(resolve => {
            let center = !!props.center ? numberizeLatLng(props.center) : undefined;

            map.value = new mapsApi.value.Map(mapElement.value, {
                center: center,
                zoom: props.zoom || mapOptions.zoom,
                mapTypeId: props.mapTypeId,
            });

            mapLoaded.value = true;
            mapEvents.value.emit('map:created', map);
            resolve();
        });
    };

    const createMapMarker = (lat, lng, markerOptions = {}) => {
        return new mapsApi.value.Marker({
            position: {
                lat,
                lng
            },
            ...markerOptions,
            identifier: markerOptions.id || generateId(),
        })
    };

    const createInfoWindow = (content = '', options = {}) => {
        return new mapsApi.value.InfoWindow({
            content,
            ...options,
            identifier: options.id || generateId(),
        });
    };

    const addMarkerToMap = (mapMarker) => {
        mapMarkers.value.set(mapMarker.identifier, mapMarker);
        mapMarker.setMap(map.value);
    };

    const centerMapOnMarker = (marker, zoom) => {
        map.value.setCenter(marker.position);
        map.value.setZoom(zoom || props.zoom || mapOptions.zoom);
    };

    const removeMapMarker = (marker) => {
        marker.setMap(null);
        mapsApi.value.event.clearInstanceListeners(marker);
        marker.removeListener('click');
    };

    const removeMapMarkers = (markers = null) => {
        for (let mapMarker of (markers || Array.from(mapMarkers.value.values()))) {
            removeMapMarker(mapMarker);
        }
    };

    const setMapCenter = (lat, lng, zoom = null) => {
        map.value.setCenter(new mapsApi.value.LatLng(lat, lng));
        map.value.setZoom(zoom || props.zoom || mapOptions.zoom);
    };

    const numberizeLatLng = (obj) => {
        if (obj.hasOwnProperty('lat')) {
            obj.lat = Number(obj.lat);
        }
        if (obj.hasOwnProperty('lng')) {
            obj.lng = Number(obj.lng);
        }
        return obj;
    };

    const getMapMarker = (markerId) => {
        return mapMarkers.value.get(markerId);
    };

    const createMarkerInfoWindow = (marker, html, options = {}) => {
        let infoWindow = createInfoWindow(html, options);

        attachInfoWindowToMarker(infoWindow, marker);

        return infoWindow;
    };

    const attachInfoWindowToMarker = (infoWindow, mapMarker) => {
        mapInfoWindows.value.set(infoWindow.identifier, {
            markerId: mapMarker.identifier,
            infoWindow
        });

        mapMarker.addListener('click', (e) => {
            let openWindow = true;

            const shouldOpen = (open = true) => {
                openWindow = open;
            };

            mapEvents.value.emit('infoWindow:click', {
                event: e,
                mapMarker,
                infoWindow,
                shouldOpen,
            });

            if (props.singleInfoWindow || !props.hasOwnProperty('singleInfoWindow') && mapOptions.singleInfoWindow) {
                closeMarkerInfoWindowsExceptId(mapMarker.identifier);
            }

            if (openWindow) {
                infoWindow.open({
                    anchor: mapMarker,
                    map: map.value,
                    shouldFocus: props.hasOwnProperty('shouldFocus') ? props.shouldFocus : mapOptions.shouldFocus
                });
            }
        });

        mapEvents.value.emit('infoWindow:attached', {
            infoWindow,
            mapMarker,
        });
    };

    const closeAllInfoWindows = () => {
        for (let infoWindow of Array.from(mapInfoWindows.value.values())) {
            infoWindow.infoWindow.close();
        }
    };

    const closeMarkerInfoWindowsExceptId = (markerId) => {
        for (let infoWindow of Array.from(mapInfoWindows.value.values())) {
            if (infoWindow.markerId !== markerId) {
                infoWindow.infoWindow.close();
            }
        }
    };

    const resetMapBounds = () => {
        mapBounds.value = new mapsApi.value.LatLngBounds();
    };

    const fitMapBoundsToMarkers = (markers) => {
        for (let marker of markers) {
            mapBounds.value.extend(marker.position);
        }

        map.value.fitBounds(mapBounds.value);
    };

    const updateMapBounds = async () => {
        if (mapBounds.value === null) {
            resetMapBounds();
        }

        if (mapMarkerCount.value === 1) {
            return centerMapOnMarker(Array.from(mapMarkers.value.values())[0]);
        }

        return fitMapBoundsToMarkers(Array.from(mapMarkers.value.values()));
    };

    let prepareMapContainer = () => {
        if (props.height) {
            mapElement.value.style.setProperty('--map-height', `${props.height}px`);
        }
    };

    const initMarkers = () => {
        if (props.markers) {
            for (let marker of props.markers) {
                let newMapMarker = createMapMarker(
                    Number(marker.lat),
                    Number(marker.lng),
                    ...marker
                );
                addMarkerToMap(newMapMarker);
            }
        }
    };

    const initMap = async () => {
        await initMapsApi();

        prepareMapContainer();

        if (createOnMount.value) {
            await createMap();

            initMarkers();
        }
    };

    const onMapCreated = (callback) => {
        mapEvents.value.on('map:created', (createdMap) => {
            callback(createdMap);
        });
    };

    provide('mapState', {
        map: readonly(map),
        mapsApi: readonly(mapsApi),
        mapEvents,
        mapLoaded,
        mapMarkers: readonly(mapMarkers),
        mapInfoWindows: readonly(mapInfoWindows),
        onMapCreated,
        addMarkerToMap,
        createMapMarker,
        createInfoWindow,
    });

    watch(() => map, (currentMap, prevMap) => {
        if (currentMap) {
            mapLoaded.value = true;
        }
    });

    onMounted(async () => {
        await initMap();

        if (props.autoBounds) {
            await updateMapBounds();
        }
    });

    onBeforeUnmount(() => {
        if (hasMapMarkers.value) {
            removeMapMarkers();
        }

        if (mapEvents.value.hasEvents()) {
            mapEvents.value.removeAll();
        }
    });

    return {
        mapsApi,
        mapId,
        mapElement,
        mapLoaded,
        mapEvents,
        map,
        mapMarkers,
        mapMarkerCount,
        hasMapMarkers,
        mapBounds,
        createMap,
        onMapCreated,
        createMapMarker,
        createInfoWindow,
        addMarkerToMap,
        centerMapOnMarker,
        removeMapMarkers,
        createMarkerInfoWindow,
        attachInfoWindowToMarker,
        resetMapBounds,
        fitMapBoundsToMarkers,
        updateMapBounds,
        closeAllInfoWindows,
        setMapCenter,
        numberizeLatLng,
    };
};

export default useMap;
