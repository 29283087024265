<template>
    <ul class="helpers__links" v-if="items.length">
        <li class="helpers__link" v-for="item in items" :key="item">
            <a href="#" @click.prevent="onHelperClick(item)">{{ item.label }}</a>
        </li>
    </ul>
    <p v-else>No helpers</p>
</template>

<script>
import {inject} from "vue";

export default {
    props: {
        items: {
            type: Array
        },
    },

    setup() {
        const helpers = inject('helpers');
        const setActiveSection = inject('setActiveSection');

        const onHelperClick = (item) => {
            if (helpers.value.has(item.name)) {
                let helper = helpers.value.get(item.name);
                setActiveSection(helper.tab);
            }
        };

        return {
            onHelperClick
        };
    },
}
</script>
