(function () {

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    };

    const direction = {
        INITIAL: 0,
        UP: 1,
        DOWN: 2,
    };

    const header = {
        element: document.querySelector('.site-header'),
        height: null,
        isTransparent() {
            return this.element.classList.contains('site-header--transparent');
        },
        setHeight(height) {
            this.height = height;
            document.documentElement.style.setProperty('--site-header-height', `${height}px`);
        },
        addClass(name) {
            this.element.classList.add(name);
        },
        removeClass(name) {
            this.element.classList.remove(name);
        },
        delayedAddClass(name, timeout = 50) {
            setTimeout(() => {
                this.addClass('site-header--top');
            }, timeout);
        },
        delayedRemoveClass(name, timeout = 50) {
            setTimeout(() => {
                this.removeClass(name);
            }, timeout);
        }
    };

    const scroll = {
        updating: false,
        current: null,
        previous: window.scrollY || document.documentElement.scrollTop,
        direction: {
            current: direction.INITIAL,
            previous: direction.INITIAL,
        },
        setCurrent(current = null) {
            this.current = current || window.scrollY || document.documentElement.scrollTop;
        },
        updatePrev(prev = null) {
            this.previous = prev || this.current;
        },
        get currDirection() {
            return this.direction.current;
        },
        set currDirection(current) {
            this.direction.current = current;
        },
        get prevDirection() {
            return this.direction.previous;
        },
        set prevDirection(previous) {
            this.direction.previous = previous;
        },
        refreshDirection() {
            if (this.current > this.previous) {
                this.currDirection = direction.DOWN;
            } else if (this.current < this.previous) {
                this.currDirection = direction.UP;
            }
        },
        isUp() {
            return this.currDirection === direction.UP;
        },
        isDown() {
            return this.currDirection === direction.DOWN;
        },
        isWithinHeader() {
            return this.current <= header.height;
        },
        isDirectionChanged() {
            return this.currDirection !== this.prevDirection;
        }
    };

    function updateHeader() {
        scroll.refreshDirection();

        if (scroll.isWithinHeader()) {
            header.removeClass('site-header--scrolling');
            header.removeClass('site-header--fixed');
        } else {
            header.addClass('site-header--scrolling');
        }

        if (header.isTransparent() && scroll.isWithinHeader()) {
            header.addClass('site-header--top');
        } else if (header.isTransparent()) {
            header.delayedRemoveClass('site-header--top', 50);
        }

        if (scroll.isDirectionChanged()) {
            toggleFixedHeader();
        }

        scroll.updatePrev();
    }

    function toggleFixedHeader() {
        if (scroll.isUp() && !scroll.isWithinHeader()) {

            setTimeout(function () {
                header.addClass('site-header--fixed');
                scroll.updatePrev();
            }, 20);

        } else if (scroll.isDown()) {
            header.removeClass('site-header--fixed');
            scroll.updatePrev();
        }
    }

    if (header.element) {
        header.setHeight(header.element.offsetHeight);

        window.addEventListener('load', function (e) {
            scroll.setCurrent();
            updateHeader();
        });

        window.addEventListener('scroll', function (e) {
            scroll.setCurrent();

            if (!scroll.updating) {
                window.requestAnimationFrame(debounce(function () {
                    updateHeader();
                    scroll.updating = false;
                }, 200));

                scroll.updating = true;
            }
        });

        window.addEventListener("resize", function () {
            header.setHeight(header.element.offsetHeight);
        });
    }
})();
