class MapInfoHtml {
    constructor(html) {
        this.html = html || '';
        this.data = {};
    }

    static fromData(data) {
        let html = new MapInfoHtml();

        html.data = data;

        html.parseData();

        return html.content();
    }

    parseData() {
        this.html = '<div class="map-info">';

        let titleLink = '';
        if (this.hasDataFor('title_link')) {
            titleLink = `<a href="${this.data.title_link}">`;
        }

        if (this.hasDataFor('title')) {
            this.html += `<h3 class="map-info__title">${titleLink}${this.data.title}${titleLink ? '</a>' : ''}</h3>`;
        }

        if (this.hasDataFor('street_address')) {
            this.html += `<p class="map-info__line">${this.data.street_address}</p>`;
        }

        let addressString = '';
        if (this.hasDataFor('city')) {
            addressString = `<p class="map-info__line">${this.data.city}`;
        }
        if (this.hasDataFor('state')) {
            addressString += !!addressString ? `, ${this.data.state}` : `<p class="map-info__line">${this.data.state}`;
        }
        if (this.hasDataFor('zip')) {
            addressString += !!addressString ? ` ${this.data.zip}` : `<p class="map-info__line">${this.data.zip}`;
        }
        if (!!addressString) {
            addressString += '</p>';
        }
        this.html += addressString;

        if (this.hasDataFor('phone')) {
            this.html += `<p class="map-info__line"><a href="tel:${this.data.phone}">${this.data.phone}</a></p>`;
        }
        if (this.hasDataFor('email')) {
            this.html += `<p class="map-info__line"><a href="mailto:${this.data.email}">${this.data.email}</a></p>`;
        }

        if (this.hasDataFor('more_link')) {
            let moreText = this.hasDataFor('more_text') ? this.data.more_text : 'Learn more';
            this.html += `<p class="map-info__line"><a href="${this.data.more_link}" target="_blank">${moreText}</a></p>`;
        }

        this.html += '</div>'

        return this.html;
    }

    hasDataFor(name) {
        return this.data.hasOwnProperty(name) && !!this.data[name];
    }

    content() {
        return this.html;
    }
}

export default MapInfoHtml;
