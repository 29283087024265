import Library from "./Library";
import Usage from "./Usage";

/**
 * Tabs: Icons [Design]
 */
export default {
    library: Library,
    usage: Usage,
};
