import {h} from 'vue';
import Subpage from "../../subpage";
import SubpageTabs from "../../SubpageTabs";
import tabs from "./tabs";

const NAME = 'paragraphs';

export default {
    components: {
        Subpage,
        SubpageTabs
    },

    setup(props, {slots}) {
        return () => h(
            Subpage, {
                name: NAME,
            }, () => [h(SubpageTabs, {
                tabs: tabs
            }, slots.default)]);
    }
};
