import {h} from 'vue';
import Subpage from "../../subpage";
import SubpageTabs from "../../SubpageTabs";
import tabs from "./tabs";

const NAME = 'colors';

export default {
    components: {
        Subpage,
        SubpageTabs
    },

    setup() {
        return () => h(
            Subpage, {
                name: NAME,
            }, h(SubpageTabs, {
                tabs: tabs
            }));
    }
};
