import { ref, reactive } from 'vue';

const useIntersectionObserver = (observerCallback) => {

    const observer = ref(null);

    const initialized = ref(false);

    const observerOptions = reactive({
        rootMargin: '50px 0px 20% 0px',
        threshold: 0
    });

    const initObserver = (callback, withOptions = {}) => {
        return new Promise((resolve) => {
            if (initialized.value) {
                return;
            }

            observer.value = new IntersectionObserver(callback || observerCallback, {
                ...observerOptions,
                withOptions
            });

            initialized.value = true;

            resolve();
        });
    };

    const observe = (element) => {
        observer.value.observe(element);
    };

    const observeAll = (elements = []) => {
        elements.forEach(element => observe(element));
    };

    const unobserve = (element) => {
        observer.value.unobserve(element);
    };

    const disconnect = () => {
        observer.value.disconnect();
    };

    return {
        observer,
        initialized,
        observerOptions,
        initObserver,
        observe,
        observeAll,
        unobserve,
        disconnect,
    };
};

export default useIntersectionObserver;
