const demoCardData = {
    titleLine1: 'Lorem ipsum dolor',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    imagePath: '/app/themes/tmbase/dist/images/thumbnail-150.png',
    imageAlt: 'Sample Alt Text',
    href: '#',
    classes: '',
    overlayColor: 'transparent',
};

export default demoCardData;
