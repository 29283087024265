import {ref, h} from "vue";
import Tabs from "../components/tabs/Tabs";
import Tab from "../components/tabs/Tab";
import TabPanels from "../components/tabs/TabPanels";
import TabPanel from "../components/tabs/TabPanel";

const useTabs = (initialTab) => {

    const activeTab = ref(initialTab ? initialTab : 0);

    const setActiveTab = (tab) => {
        activeTab.value = tab;
    };

    const createTabsComponent = (tabsProps, items) => {
        return h(Tabs, {
            modelValue: activeTab,
            'onUpdate:modelValue': (val) => setActiveTab(val),
            ...tabsProps
        }, () => items);
    };

    const createTabComponent = (name, tabProps) => {
        return h(Tab, tabProps, () => name);
    };

    const createTabPanelsComponent = (panelsProps, items) => {
        return h(TabPanels, {
            modelValue: activeTab,
            ...panelsProps
        }, () => items);
    };

    const createTabPanelComponent = (panelProps, content) => {
        return h(TabPanel, panelProps, content);
    };

    const tabs = ref([]);
    const tabPanels = ref([]);

    const addTab = (item, index) => {
        if (index !== undefined) {
            tabs.value[index] = item;
        } else {
            tabs.value.push(item);
        }
    };
    const addTabPanel = (item, index) => {
        if (index !== undefined) {
            tabPanels.value[index] = item;
        } else {
            tabPanels.value.push(item);
        }
    };

    return {
        activeTab,
        setActiveTab,
        createTabsComponent,
        createTabComponent,
        createTabPanelsComponent,
        createTabPanelComponent,
        tabs,
        tabPanels,
        addTab,
        addTabPanel,
    };
};

export default useTabs;
