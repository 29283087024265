import Headings from "./headings";
import Paragraphs from "./paragraphs";
import Lists from "./lists";
import Buttons from "./buttons";
import Forms from "./forms";
import Tables from "./tables";

export default {
    elements: [
        {
            name: 'headings',
            label: 'Headings',
            component: Headings
        },
        {
            name: 'paragraphs',
            label: 'Paragraphs',
            component: Paragraphs
        },
        {
            name: 'buttons',
            label: 'Buttons',
            component: Buttons
        },
        {
            name: 'lists',
            label: 'Lists',
            component: Lists
        },
        {
            name: 'forms',
            label: 'Forms',
            component: Forms
        },
        {
            name: 'tables',
            label: 'Tables',
            component: Tables
        },
    ],
};
