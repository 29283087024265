<template>
    <div>
        <section id="flash" class="flash">
            <header class="section-header container">
                <h3 class="section-title">
                    Flash Messages
                </h3>
                <hr>
            </header>
            <div class="container">
                <div class="flash-alert">
                    Alert
                </div>
                <div class="flash-error">
                    Error
                </div>
                <div class="flash-notice">
                    Notice
                </div>
                <div class="flash-success">
                    Success
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {}
</script>
