import Usage from "./Usage";
import Style from "./Style";
import Code from "./Code";
import Accessibility from "./Accessibility";

/**
 * Tabs: Accordion
 */
export default {
    usage: Usage,
    style: Style,
    code: Code,
    accessibility: Accessibility,
};
