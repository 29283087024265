export default {
    name: 'backgrounds',
    label: 'Backgrounds',
    items: [
        {
            name: 'background_color',
            label: 'Background Color',
            description: 'Used to control the background color of an element.',
            helpers: [
                {
                    class: 'bg-primary',
                    properties: [
                        'background-color: $primary-color;'
                    ]
                },
                {
                    class: 'bg-white',
                    properties: [
                        'background-color: #fff;'
                    ]
                },
            ]
        }
    ]
};
