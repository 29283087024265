<template>
    <div>
        <table>
            <tr>
                <th>Years</th>
                <th>President</th>
                <th>Vice President</th>
            </tr>
            <tr>
                <td>2021 - Present</td>
                <td>Joe Biden</td>
                <td>Kamala Harris</td>
            </tr>
            <tr>
                <td>2017 - 2021</td>
                <td>Donald Trump</td>
                <td>Mike Pence</td>
            </tr>
            <tr>
                <td>2009 - 2018</td>
                <td>Barack Obama</td>
                <td>Joe Biden</td>
            </tr>
            <tr>
                <td>2001 - 2009</td>
                <td>George W. Bush</td>
                <td>Richard Cheney</td>
            </tr>
            <tr>
                <td>1993 - 2001</td>
                <td>Bill Clinton</td>
                <td>Albert Gore</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {}
</script>
