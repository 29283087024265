<template>
    <div>
        <helper-links :items="helpers"></helper-links>
    </div>
</template>

<script>
import HelpersLinkView from "../../helpers/HelpersLinkView";

export default {
    components: {
        'helper-links': HelpersLinkView,
    },

    data() {
        return {
            helpers: [
                {
                    name: 'backgrounds.background_color',
                    label: 'Background Color'
                },
                {
                    name: 'typography.text_color',
                    label: 'Text Color'
                },
            ]
        };
    }
}
</script>
