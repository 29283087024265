import ScrollLink from "@/components/navigation/ScrollLink";
import Accordion from "@/components/accordion/Accordion";
import Carousel from "@/components/carousel/Carousel";
import Cards from "@/components/cards/Cards";
import Card from "@/components/cards/Card";
import Lightbox from "@/components/lightbox/Lightbox";
import Search from "@/components/search/Search";
import VideoPlayer from "@/components/video/VideoPlayer";
import LazyVideo from "@/components/video/LazyVideo";

// TMP (need better solution)
import PageStyleguide from "../pages/styleguide/PageStyleguide";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'tm-scroll-link': ScrollLink,
    'tm-accordion': Accordion,
    'tm-carousel': Carousel,
    'tm-cards': Cards,
    'tm-card': Card,
    'tm-lightbox': Lightbox,
    'tm-search': Search,
    'tm-video': VideoPlayer,
    'tm-lazy-video': LazyVideo,
    'tm-page-styleguide': PageStyleguide,
};
