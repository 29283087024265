<template>
    <div>
        <header class="section-header container">
            <h3 class="section-title">
                Body Text
            </h3>
            <hr>
        </header>
        <div class="section-body container">
            <p class="large">Large paragraph body text. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab
                accusantium
                amet animi aut cumque dignissimos dolore doloremque dolorum eveniet impedit iure officia officiis,
                quaerat
                quidem quo quod soluta voluptate voluptatibus? Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam
                aliquid at consequuntur cum delectus, deserunt et excepturi illo laboriosam nostrum quidem repellat
                saepe vero
                voluptas voluptate. Dolorem hic laborum maiores!</p>
            <blockquote>Blockquote. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere molestias nesciunt,
                obcaecati officia praesentium temporibus velit? Aliquid atque culpa delectus, dignissimos dolores, ipsa
                libero
                neque non saepe soluta veniam veritatis.
            </blockquote>
            <p><a href="javascript:void(0)">I'm a link, you can click me</a></p>
        </div>
    </div>
</template>

<script>
export default {}
</script>
