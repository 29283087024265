<template>
    <div>
        <p>The "Select" component leverages the Select2 library for its functionality.</p>
        <p>Here's an example Select in action:</p>

        <div class="select-demo-wrapper">
            <tm-select :options="options" v-model="selected">
                <option disabled value="0">Select one</option>
            </tm-select>
        </div>

        <p>The code structure in the template for the above looks like this:</p>

        <pre><code v-text="codeSnippet"></code></pre>

        <p>The v-model "selected" and the array of "options" are stored as data. Of course, the "options" could also be a computed value.</p>

        <p>In your component it would look like this:</p>

        <pre><code v-text="codeSnippetTwo"></code></pre>
    </div>
</template>

<script>
import Select from "../../../../components/select/Select";

export default {
    components: {
        'tm-select': Select,
    },

    data() {
        return {
            selected: 2,
            options: [{ id: 1, text: "Option 1" }, { id: 2, text: "Option 2"}, { id: 3, text: "Option 3" }, { id: 4, text: "Option 4" }],
            codeSnippet: '<tm-select :options="options" v-model="selected">\n' +
                '    <option disabled value="0">Select one</option>\n' +
                '</tm-select>',
            codeSnippetTwo: 'data() {\n' +
                '    return {\n' +
                '        selected: 2,\n' +
                '        options: [{ id: 1, text: "Option 1" }, { id: 2, text: "Option 2"}, { id: 3, text: "Option 3" }, { id: 4, text: "Option 4" }],\n' +
                '    };\n' +
                '},'
        };
    },
}
</script>

<style lang="scss">
.select-demo-wrapper {
    margin: 1.5rem 0 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px;
}
</style>
