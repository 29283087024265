import Accordion from "./accordion";
import Carousel from "./carousel";
import Cards from "./cards";
import Lightbox from "./lightbox";
import Select from "./select";
import Tabs from "./tabs";
import Video from "./video";

export default {
    components: [
        {
            name: 'accordion',
            label: 'Accordion',
            component: Accordion
        },
        {
            name: 'carousel',
            label: 'Carousel',
            component: Carousel
        },
        {
            name: 'cards',
            label: 'Cards',
            component: Cards
        },
        // {
        //     name: 'modals',
        //     label: 'Modals',
        //     component: Modals
        // },
        {
            name: 'lightbox',
            label: 'Lightbox',
            component: Lightbox
        },
        {
            name: 'select',
            label: 'Select',
            component: Select
        },
        {
            name: 'tabs',
            label: 'Tabs',
            component: Tabs
        },
        {
            name: 'video',
            label: 'Video',
            component: Video
        },
    ],
};
