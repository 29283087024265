<template>
    <div class="map__container">
        <div :id="mapId"
             ref="mapElement"
             class="map"></div>

        <template v-if="mapLoaded">
            <slot>
                <map-marker v-for="(marker, index) in markers"
                            :lng="marker.lng"
                            :lat="marker.lat"
                            :key="index"></map-marker>
            </slot>
        </template>
    </div>
</template>

<script>
import MapMarker from "./map-marker";
import MapInfoHtml from "./map-info-html";
import useMap from "./use-map";

export default {
    components: {
        MapMarker,
    },

    props: {
        id: {
            type: String
        },
        height: {
            type: Number
        },
        mapData: {
            type: [Array, Object]
        },
        markers: {
            type: Array
        },
        center: {
            type: Object,
            validator: val => {
                if (val !== undefined) {
                    return val.hasOwnProperty('lat') && val.hasOwnProperty('lng');
                }
                return true;
            }
        },
        zoom: {
            type: Number,
            default: 8
        },
        mapTypeId: {
            type: String,
            default: 'roadmap'
        },
        // whether to automatically extend the bounds
        // to include each marker's position
        autoBounds: {
            type: Boolean,
            default: true
        }
    },

    setup(props, {emit, slots, attrs}) {

        const {
            mapId,
            mapElement,
            mapLoaded,
            map,
            mapMarkers,
            onMapCreated,
            createMapMarker,
            createMarkerInfoWindow,
            addMarkerToMap,
            closeAllInfoWindows,
            numberizeLatLng,
            setMapCenter,
        } = useMap(props);

        const addLocationInfoWindow = (marker, infoWindowData) => {
            let htmlContent;
            if (infoWindowData.hasOwnProperty('html') && !!infoWindowData.html) {
                htmlContent = infoWindowData.html;
            } else {
                htmlContent = MapInfoHtml.fromData(infoWindowData);
            }
            createMarkerInfoWindow(marker, htmlContent);
        };

        const addLocations = (locations) => {
            for (let location of locations) {
                let marker = createMapMarker(
                    Number(location.lat),
                    Number(location.lng),
                    // todo: other options from props/mapData
                );

                if (location.info_window) {
                    addLocationInfoWindow(marker, location.info_window);
                }

                addMarkerToMap(marker);
            }
        };

        const parseMapData = (mapData, options = {}) => {
            if (mapData.hasOwnProperty('locations')) {
                addLocations(mapData.locations, options);
            }
        };

        onMapCreated(() => {
            if (props.mapData) {
                parseMapData(props.mapData);
            } else if (props.center) {
                let center = !!props.center ? numberizeLatLng(props.center) : undefined;
                setTimeout(() => {
                    setMapCenter(center.lat, center.lng);
                }, 50);
            }

            map.value.addListener('click', (e) => {
                closeAllInfoWindows();
            });
        });

        return {
            mapLoaded,
            mapId,
            mapElement,
            map,
            mapMarkers,
        };
    },
};
</script>

<style lang="scss">
.map-info {
    padding: $base-spacing / 2;

    &__title {
        font-size: scale-font(18px, 22px);
        margin-bottom: 0.75rem;
    }

    &__line {
        margin-bottom: 0.5rem;
    }
}
</style>
