import design from "./design";
import elements from "./elements";
import components from "./components";
import shortcodes from "./shortcodes";
import helpers from "./helpers";

/**
 * Styleguide section configuration.
 */
export default {
    ...design,
    ...components,
    ...elements,
    ...shortcodes,
    ...helpers
};
