<template>
    <div>
        <p>The "Lightbox" component uses the <a href="https://fslightbox.com/vue" target="_blank">fs-lightbox library</a> for its functionality.</p>

        <h4>Default Lightbox (grid)</h4>

        <p>Here's an example Lightbox in action, using the default "display-type" of "grid":</p>

        <div class="lightbox-demo-wrapper">
            <button class="lightbox-trigger" data-lightbox-name="lightbox-one">Open lightbox 1</button>
            <tm-lightbox :items="images" name="lightbox-one"></tm-lightbox>
        </div>

        <p>The code structure in the template for the above looks like this:</p>

        <pre><code v-text="codeSnippet"></code></pre>

        <h4>Lightbox with Carousel</h4>

        <p>Here's an example a Lightbox with a Carousel (the "display-type" prop set to "carousel"):</p>

        <div class="lightbox-demo-wrapper">
            <button class="lightbox-trigger" data-lightbox-name="lightbox-two">Open lightbox 2</button>
            <tm-lightbox :items="images" display-type="carousel" name="lightbox-two"></tm-lightbox>
        </div>

        <p>The code structure for the above looks like this:</p>

        <pre><code v-text="codeSnippetTwo"></code></pre>

        <p>The "images" variable being passed to the "items" prop should be an array of objects, which should look like the following:</p>

        <pre><code v-text="codeSnippetThree"></code></pre>
    </div>
</template>

<script>
import Lightbox from "../../../../components/lightbox/Lightbox";

export default {
    components: {
        'tm-lightbox': Lightbox,
    },

    data() {
        return {
            selected: 2,
            images: [{
                url: 'https://picsum.photos/300?random=1',
                text: 'Example image 1'
            },{
                url: 'https://picsum.photos/200?random=1',
                text: 'Example image 2'
            },{
                url: 'https://picsum.photos/400?random=1',
                text: 'Example image 3'
            }],
            codeSnippet: '<button class="lightbox-trigger" data-lightbox-name="lightbox-one">Open lightbox</button>\n' +
                '<tm-lightbox :items="images" name="lightbox-one"></tm-lightbox>',
            codeSnippetTwo: '<button class="lightbox-trigger" data-lightbox-name="lightbox-two">Open lightbox 2</button>\n' +
                '<tm-lightbox :items="images" display-type="carousel" name="lightbox-two"></tm-lightbox>',
            codeSnippetThree: 'export default {\n' +
                '    data() {\n' +
                '        return {\n' +
                '            images: [{\n' +
                '                url: \'https://picsum.photos/300?random=1\',\n' +
                '                text: \'Example image 1\'\n' +
                '            },{\n' +
                '                url: \'https://picsum.photos/200?random=1\',\n' +
                '                text: \'Example image 2\'\n' +
                '            },{\n' +
                '                url: \'https://picsum.photos/400?random=1\',\n' +
                '                text: \'Example image 3\'\n' +
                '            }]\n' +
                '        };\n' +
                '    },\n' +
                '}',
        };
    },
}
</script>

<style lang="scss">
.lightbox-demo-wrapper {
    margin: 1.5rem 0 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px;
}
</style>
