<template>
    <section :id="`helpers-list-${name}`" class="styleguide__subpage styleguide__section">
        <header class="styleguide__subpage-header">
            <h2 class="styleguide__subpage-title">{{ subpageTitle }}</h2>
        </header>

    </section>
    <div class="styleguide__subpage-content">
        <accordion :items="helperItems"></accordion>
    </div>
</template>

<script>
import {computed} from "vue";
import StyleguideSubpage from "../subpage";
import StyleguideSubpageTab from "../subpage-tab";
import Accordion from "../../../components/accordion/Accordion";
import {slugToTitle} from "../../../util/helpers";

export default {
    components: {
        'sub-page': StyleguideSubpage,
        'sub-page-tab': StyleguideSubpageTab,
        Accordion,
    },

    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
        },
        items: {
            type: Array
        }
    },

    setup(props) {
        const subpageTitle = computed(() => {
            let title = props.title ? props.title : slugToTitle(props.name);
            return `Helpers: ${title}`;
        });

        const helperItems = computed(() => {
            return props.items.map(helperItem => {
                let helperLabel = helperItem.label ? helperItem.label : slugToTitle(helperItem.name);
                return {
                    title: helperLabel,
                    body: `(helper classes for ${helperLabel})`
                };
            });
        });

        return {
            subpageTitle,
            helperItems
        };
    },
}
</script>
