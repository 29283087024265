<template>
    <div>
        <p>There are two primary sections to tabs: the actual "Tabs" &mdash; i.e. the "buttons" which are used to navigate &mdash; and then the "Tab Panel(s)", which is where the content for each tab is actually output/displayed.</p>
        <p>Here are example tabs in action:</p>

        <div class="tabs-demo-wrapper">
            <tm-tabs v-model="activeTab">
                <tm-tab name="board">Board Meeting</tm-tab>
                <tm-tab name="council">Council Meeting</tm-tab>
                <tm-tab name="staff">Staff Meeting</tm-tab>
            </tm-tabs>

            <tm-tabpanels v-model="activeTab">
                <tm-tabpanel name="board">
                    Board meeting tab content!
                </tm-tabpanel>
                <tm-tabpanel name="council">
                    Council meeting tab content!
                </tm-tabpanel>
                <tm-tabpanel name="staff">
                    Staff meeting tab content!
                </tm-tabpanel>
            </tm-tabpanels>
        </div>

        <p>The code structure in the template for the above looks like this:</p>

        <pre><code v-text="codeSnippet"></code></pre>

        <p>You'll notice that there is a <code>v-model</code> in place called <code>activeTab</code> &mdash; this keeps the active tab value in-sync with the tab panels. This data variable can be called anything (i.e. it doesn't have to be "activeTab"), and you should set the initial value to the tab "name" of whichever should start as the active tab.</p>

        <p>In your component it would look like this:</p>

        <pre><code v-text="codeSnippetTwo"></code></pre>
    </div>
</template>

<script>
import Tabs from "../../../../components/tabs/Tabs";
import Tab from "../../../../components/tabs/Tab";
import TabPanels from "../../../../components/tabs/TabPanels";
import TabPanel from "../../../../components/tabs/TabPanel";

export default {
    components: {
        'tm-tabs': Tabs,
        'tm-tab': Tab,
        'tm-tabpanels': TabPanels,
        'tm-tabpanel': TabPanel,
    },

    data() {
        return {
            activeTab: 'board',
            codeSnippet: '<tm-tabs v-model="activeTab">\n' +
                '    <tm-tab name="board">Board Meeting</tm-tab>\n' +
                '    <tm-tab name="council">Council Meeting</tm-tab>\n' +
                '    <tm-tab name="staff">Staff Meeting</tm-tab>\n' +
                '</tm-tabs>\n' +
                '\n' +
                '<tm-tabpanels v-model="activeTab">\n' +
                '    <tm-tabpanel name="board">\n' +
                '      Board meeting tab content!\n' +
                '    </tm-tabpanel>\n' +
                '    <tm-tabpanel name="council">\n' +
                '      Council meeting tab content!\n' +
                '    </tm-tabpanel>\n' +
                '    <tm-tabpanel name="staff">\n' +
                '      Staff meeting tab content!\n' +
                '    </tm-tabpanel>\n' +
                '</tm-tabpanels>',
            codeSnippetTwo: 'export default {\n' +
                '    data() {\n' +
                '        return {\n' +
                '            activeTab: \'board\',\n' +
                '        };\n' +
                '    },\n' +
                '}',
        };
    },
}
</script>

<style lang="scss">
.tabs-demo-wrapper {
    margin: 1.5rem 0 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
</style>
