<template>
    <section class="sidebar">
        <header v-if="title" class="sidebar__header">
            <h3>{{ title }}</h3>
        </header>

        <nav class="sidebar__nav">
            <ul class="sidebar__nav-items">
                <li v-for="item in sidebarItems"
                    :key="item.name"
                    class="sidebar__nav-item">

                    <button v-if="item.hasOwnProperty('children')"
                            type="button"
                            class="sidebar__submenu"
                            :class="{'sidebar__submenu--active': item.active}"
                            :aria-expanded="item.active ? 'true' : 'false'"
                            @click="toggleCategory(item.name)">
                        <span>{{ item.label }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="sidebar__submenu-icon" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </button>
                    <a v-else-if="item.hasOwnProperty('href')"
                       :href="item.href"
                       class="sidebar__link">{{ item.label }}</a>
                    <button v-else
                            type="button"
                            class="sidebar__submenu"
                            :class="{'sidebar__submenu--active': item.active}"
                            :aria-expanded="item.active ? 'true' : 'false'"
                            :disabled="item.active"
                            @click="onItemClick(item.name)">
                        <span>{{ item.label }}</span>
                    </button>

                    <slide-vertical v-if="item.hasOwnProperty('children') && item.children.length"
                                    :active="item.active">
                        <ul class="sidebar__menu">
                            <li v-for="itemChild in item.children"
                                :key="itemChild.name"
                                class="sidebar__menu-item"
                                :class="{'sidebar__menu-item--active': activeSection === itemChild.name}"
                                @click="onItemClick(itemChild.name)">
                                {{ itemChild.label }}
                            </li>
                        </ul>
                    </slide-vertical>
                </li>
            </ul>
        </nav>
    </section>
</template>

<script>
import {ref, computed, watch, inject, onMounted} from "vue";
import SlideVertical from "../transitions/SlideVertical";

export default {
    components: {
        SlideVertical,
    },

    props: {
        items: {
            type: Array
        },
        title: {
            type: String
        }
    },

    setup(props, {emit}) {
        const activeSection = inject('activeSection', null);

        const sidebarItemMap = ref(new Map());

        const setSidebarItems = (items) => {
            for (const item of items) {
                let isActive = item.active && !activeSection.value || item.name === activeSection.value;

                // check whether item has the active child item
                if (!isActive && activeSection.value && item.hasOwnProperty('children')) {
                    let activeChildIndex = item.children.findIndex(child => child.name === activeSection.value);
                    isActive = activeChildIndex >= 0;
                }

                sidebarItemMap.value.set(item.name, {
                    ...item,
                    active: isActive
                });
            }
        };

        const sidebarItems = computed(() => {
            return Array.from(sidebarItemMap.value.values());
        });

        const toggleCategory = (name) => {
            let category = sidebarItemMap.value.get(name);
            category.active = !category.active;
            sidebarItemMap.value.set(name, category);
        };

        const onItemClick = (name) => {
            emit('item:clicked', name);
        };

        watch(activeSection, () => setSidebarItems(Array.from(sidebarItemMap.value.values())));

        onMounted(() => {
            setSidebarItems(props.items);
        });

        return {
            activeSection,
            sidebarItemMap,
            sidebarItems,
            toggleCategory,
            onItemClick,
        };
    }
}
</script>
