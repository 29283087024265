<template>
    <tm-cards :cards="cards"></tm-cards>
</template>

<script>
import Cards from "../../../../components/cards/Cards";
import demoCardData from "./demo-data";

export default {
    components: {
        'tm-cards': Cards,
    },

    data() {
        return {
            demoCardCount: 3,
            cards: []
        };
    },

    mounted() {
        for (let i = 0; i < this.demoCardCount; i++) {
            this.cards.push({
                ...demoCardData,
                title: `${demoCardData.titleLine1} ${i + 1}`
            });
        }
    }
}
</script>
