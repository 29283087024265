<template>
    <div>
        <div class="mb-lg">
            <h4 class="mb-md">Accordion with defaults:</h4>
            <tm-accordion :items="demoItems"></tm-accordion>
        </div>
        <hr>
        <div class="mb-lg">
            <h4 class="mb-md">Accordion with "single-open" prop:</h4>
            <tm-accordion single-open :items="demoItems"></tm-accordion>
        </div>
        <hr>
        <div class="mb-lg">
            <h4 class="mb-md">Accordion with "no-icon" prop set on accordion items:</h4>
            <tm-accordion no-icon :items="demoItems"></tm-accordion>
        </div>
    </div>
</template>

<script>
import Accordion from "../../../../components/accordion/Accordion";

export default {
    components: {
        'tm-accordion': Accordion,
    },

    data() {
        return {
            demoItems: [
                {
                    title: 'Accordion Item One',
                    body: 'This is some content for the accordion item one. Amet mattis vulputate enim nulla. Neque viverra justo nec ultrices dui sapien eget mi. Dui sapien eget mi proin sed. Risus ultricies tristique nulla aliquet. Mi bibendum neque egestas congue quisque egestas. Turpis massa sed elementum tempus egestas. Libero justo laoreet sit amet cursus sit amet dictum. At erat pellentesque adipiscing commodo elit at imperdiet. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Sed viverra tellus in hac habitasse platea dictumst.',
                },
                {
                    title: 'Accordion Item Two',
                    body: 'This is some content for the accordion item two. Amet mattis vulputate enim nulla. Neque viverra justo nec ultrices dui sapien eget mi. Dui sapien eget mi proin sed. Risus ultricies tristique nulla aliquet. Mi bibendum neque egestas congue quisque egestas. Turpis massa sed elementum tempus egestas. Libero justo laoreet sit amet cursus sit amet dictum. At erat pellentesque adipiscing commodo elit at imperdiet. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Sed viverra tellus in hac habitasse platea dictumst.',
                },
                {
                    title: 'Accordion Item Three',
                    body: 'This is some content for the accordion item three. Amet mattis vulputate enim nulla. Neque viverra justo nec ultrices dui sapien eget mi. Dui sapien eget mi proin sed. Risus ultricies tristique nulla aliquet. Mi bibendum neque egestas congue quisque egestas. Turpis massa sed elementum tempus egestas. Libero justo laoreet sit amet cursus sit amet dictum. At erat pellentesque adipiscing commodo elit at imperdiet. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Sed viverra tellus in hac habitasse platea dictumst.',
                },
            ]
        };
    },
}
</script>
