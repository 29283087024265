<template>
    <slottable-subpage name="callouts"
                       :render="render"></slottable-subpage>
</template>

<script>
import SlottableSubpage from "../slottable-subpage";

export default {
    name: 'Callouts',

    components: {
        SlottableSubpage,
    },

    props: {
        render: {
            type: Function,
            default: h => null
        }
    },
}
</script>
