import Overview from "./Overview";
import Usage from "./Usage";
import Code from "./Code";
// import Implementation from "./Implementation";
import Helpers from "./Helpers";

/**
 * Tabs: Colors
 */
export default {
    overview: Overview,
    usage: Usage,
    code: Code,
    // implementation: Implementation,
    helpers: Helpers
};
