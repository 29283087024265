/**
 * Capitalize the first letter of a string.
 *
 * @param str
 * @returns {string}
 */
export const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceAll = (string, search, replacement) => {
    return string.split(search).join(replacement);
};

/**
 * Transform a slug string to a capitalized title.
 *
 * @param slug
 * @returns {string}
 */
export const slugToTitle = (slug) => {
    return replaceAll(slug, '_', '-')
        .split('-')
        .map(str => capitalize(str))
        .join(' ');
};

/**
 * Transform a string into a slug.
 *
 * @param str
 * @returns {string}
 */
export const slugify = (str) => {
    return str
        .toLowerCase()
        .trim()
        .replace(/\/+/g, '-')
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
};

